import { registerPlugin, type PluginListenerHandle } from '@capacitor/core';

enum PKPushType {
  Voip = 'PKPushTypeVoIP',
}

interface IncomingCallPayload {
  thread_id: number;
  audio_only: boolean;
  videochat_id: number;
}

interface Version {
  version: string;
}

export interface FLCallkitPlugin {
  /**
   * Get current version of plugin
   */
  pluginVersion(): Promise<Version>;

  /**
   * Ends the callkit call, new version with videochatId
   */
  endCall(options?: { videochatId: number }): Promise<void>;

  /**
   * Registers the app to receive voip push notifications for background calling
   */
  registerVOIPPush(): Promise<void>;

  /**
   * Gets the voip push token. If one has not been registered it will be an empty string
   */
  getVOIPDeviceToken(): Promise<{
    deviceToken: string;
  }>;

  /**
   * Gets incoming call info if the application was open because of answering a call
   */
  getOnAppOpenCall(): Promise<{ payload?: IncomingCallPayload }>;

  /**
   * Sets the speakerphone mode on/off
   */
  toggleSpeakerMode(options: { speakerOn: boolean }): Promise<void>;

  /**
   * This event is fired when ios gives us the device token for
   * voip push notifications
   */
  addListener(
    eventName: 'didUpdatePKPushCredentials',
    listener: (data: { deviceToken: string; pushType: PKPushType }) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Fired during incoming call
   */
  addListener(
    eventName: 'incomingCall',
    listener: (data: {
      pushType: PKPushType;
      payload: IncomingCallPayload;
    }) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Adds listeners to pushkit / callkit events.
   * For more info see:
   *    https://developer.apple.com/documentation/callkit/cxproviderdelegate
   *    https://developer.apple.com/documentation/pushkit/pkpushregistrydelegate
   */
  addListener(
    eventName:
      | 'answerCallAction'
      | 'endCallAction'
      | 'startCallAction'
      | 'didActivateAVAudioSession'
      | 'providerDidReset',
    listener: () => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Fired when the user presses the muted button on callkit
   */
  addListener(
    eventName: 'setMutedCallAction',
    listener: (data: { muted: boolean }) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Fired when audio route changes. For example, turning on speaker mode
   * or switching to headphones.
   */
  addListener(
    eventName: 'didChangeAudioRoute',
    listener: (data: { isSpeakerOn: boolean }) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
}

export const FL_CALL_KIT_PLUGIN_NAME = 'FLCallkit';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FLCallkit = registerPlugin<FLCallkitPlugin>(
  FL_CALL_KIT_PLUGIN_NAME,
);
